<template>
    <div
        class="inputcheck max-w-full flex flex-row items-center cursor-pointer"
        @click="changeSelect"
    >
        <div
            class="w-6 h-6 border rounded-full flex items-center justify-center mr-2 border-opacity-80 transition-colors"
            :class="[
                modelValue
                    ? `bg-${activeColor} border-transparent`
                    : `bg-transparent border-grey-light`,
            ]"
        >
            <height-transition>
                <div
                    class="height-item transition-all rounded-full bg-white"
                    :class="[modelValue ? `h-3 w-3` : `h-0 w-0`]"
                ></div>
            </height-transition>
        </div>
        <label
            class="cursor-pointer text-opacity-80 transition-colors"
            :class="'text-' + defaultColor"
            :for="id"
            >{{ label }}</label
        >
    </div>
</template>

<script>
import { defineComponent } from "vue";
import HeightTransition from "../transitions/HeightTransition.vue";
export default defineComponent({
    components: { HeightTransition },
    name: "InputRadio",
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },

        defaultColor: {
            type: String,
            required: true,
        },
        activeColor: {
            type: String,
            default: "primary",
        },
    },
    emits: ["change", "update:modelValue"],
    setup(props, ctx) {
        /**
         * emits
         */
        function changeSelect() {
            ctx.emit("update:modelValue", !props.modelValue);
            ctx.emit("change", !props.modelValue);
        }

        return {
            //emits
            changeSelect,
        };
    },
});
</script>
<style lang="scss">
.inputcheck:hover {
    .checkbox,
    label {
        @apply border-opacity-100;
        @apply text-opacity-100;
    }
}
</style>
