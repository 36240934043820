<template>
    <div
        class="w-full max-w-full flex items-center text-md h-9 p-1 pl-3 pr-3 mt-1 transition-all"
    >
        <component
            :is="'CommunicationIcon'"
            class="  mr-3  "
            :fillClass="'fill-' + color"
        ></component>
        <div
            class=" overflow-hidden  whitespace-nowrap  overflow-ellipsis  font-semibold"
            :class="'text-' + color"
        >
            {{ text }}
        </div>
    </div>
</template>
<script>
import { defineComponent } from "vue";

export default defineComponent({
    name: "EmptyListFeedback",
    props: {
        text: {
            type: String,
            required: true,
        },
        color: {
            type: String,
            required: true,
        },
    },
});
</script>
