<template>
    <bottom-up-modal
        :title="$t('shortcuts')"
        :isVisible="isVisible"
        @closeModal="$emit('close')"
    >
        <div
            class="w-full mb-4 p-3 rounded-lg  bg-white-dark transition-bg-color select-none"
            v-for="(shortcut, index) in state.shortcuts"
            :key="index"
        >
            <h3 class="mb-2 text-primary font-semibold">
                {{ shortcut.letter }}
            </h3>
            <h3>{{ shortcut.description }}</h3>
        </div>
    </bottom-up-modal>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useI18n } from "vue-i18n";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";

export default defineComponent({
    components: { BottomUpModal },
    name: "ShortcutsModal",
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const i18n = useI18n();
        const state = reactive({
            shortcuts: [
                { letter: "F", description: i18n.t("filter_toggle") },
                { letter: "M", description: i18n.t("menu_toggle") },
                {
                    letter: "V",
                    description: i18n.t("fullscreen_toggle"),
                },
            ],
        });
        return { state };
    },
});
</script>
