<template>
  <bottom-up-modal
    :title="$t('edit_users_2')"
    :isVisible="isVisible"
    @closeModal="$emit('close')"
  >
    <div class="flex flex-row flex-wrap justify-between">
      <input-text
        class="w-full sm:w-50-5 lg:w-64 mb-5"
        :id="'user_first_name'"
        :label="$t('first_name')"
        :name="'first_name'"
        :labelClass="'bg-white'"
        v-model="state.user.firstname"
      ></input-text>
      <input-text
        class="w-full sm:w-50-5 lg:w-64 mb-5"
        :id="'user_last_name'"
        :label="$t('last_name')"
        :labelClass="'bg-white'"
        :name="'last_name'"
        v-model="state.user.lastname"
      ></input-text>
      <input-text
        class="w-full sm:w-50-5 lg:w-64 mb-5"
        :id="'user_email'"
        :label="$t('email')"
        :name="'email'"
        :labelClass="'bg-white'"
        v-model="state.user.email"
      ></input-text>
      <input-text
        class="w-full sm:w-50-5 lg:w-64 mb-5"
        :id="'user_mobile'"
        :label="$t('mobile')"
        :name="'mobile'"
        :labelClass="'bg-white'"
        v-model="state.user.mobile_number"
      ></input-text>
    </div>

    <div class="flex flex-row flex-wrap justify-start mt-3">
      <input-radio
        class="mr-8"
        :defaultColor="'grey-dark'"
        :name="'user-edit-modal-role'"
        :label="$t('admin')"
        :id="'user-edit-modal-role-admin'"
        :modelValue="state.user.role == 'Admin'"
        @click="state.user.role = 'Admin'"
      ></input-radio>
      <input-radio
        :defaultColor="'grey-dark'"
        :name="'user-edit-modal-role'"
        :label="$t('user')"
        :id="'user-edit-modal-role-user'"
        :modelValue="state.user.role == 'Standard'"
        @click="state.user.role = 'Standard'"
      ></input-radio>
    </div>
    <div class="flex flex-col mt-5">
      <input-checkbox
        :defaultColor="'grey-dark'"
        :activeColor="'primary'"
        :label="$t('administration')"
        :name="'user_role_1'"
        :id="'user_role_1'"
        v-model="userIsAdministrator"
        @click="
          if (userIsAdministrator) {
            state.user.roles = state.user.roles.filter((r) => {
              r.id != 1;
            });
          } else {
            state.user.roles.push({
              id: 1,
              name: 'Administration',
            });
          }
        "
      ></input-checkbox>
    </div>
    <div class="flex flex-row justify-end mb-6">
      <custom-button
        v-if="state.mode == 'edit'"
        @click="changeMode('create')"
        class="w-44 h-11 mr-4"
        :bgColor="'bg-grey-dark'"
        :text="$t('cancel')"
        :textColor="'text-white'"
        :id="'abort-user-button'"
      />
      <custom-button
        class="w-44 h-11"
        :text="state.mode == 'create' ? $t('send_invitation') : $t('save')"
        :bgColor="'bg-primary'"
        :textColor="'text-white'"
        :id="'add-user-button'"
        @click="state.mode == 'create' ? CREATE_USER() : UPDATE_USER()"
      />
    </div>
    <div class="w-full border rounded-lg border-grey-light mb-6"></div>
    <h2 class="font-semibold text-white-dark mb-3">
      {{ $t("user") }}
    </h2>
    <input-text
      class="w-80 mb-4"
      id="search-users"
      name="search-users"
      :label="$t('user_search')"
      :labelClass="'bg-white'"
      v-model="state.search"
    >
      <template #icon>
        <search-icon :fillClass="'fill-grey'"></search-icon> </template
    ></input-text>
    <div class="settings-card-box scrollbar overflow-auto pr-1">
      <list-transition>
        <regular-card
          class="w-full mb-3 bg-white-dark bg-opacity-40 hover:bg-opacity-100 list-transition-item"
          v-for="(user, index) in filteredUsers"
          :key="index"
          :isActive="state.user.id == user.id"
          :description="`${user.email}
                        `"
          :header="user.fullname"
        >
          <template #avatar
            ><avatar-bubble :name="user.fullname" class="mr-3 mt-1" 
                :textColor="`text-white text-medium`"
          /></template>
          <template #icons
            ><div class="flex flex-row items-center justify-end">
                <icon-container
                v-if="!user.accepted_invite"
                class="hover:bg-grey hover:bg-opacity-20 mr-3"
                icon="MailIcon"
                iconFill="fill-primary"
                :title="$t('resend_mail')"
              ></icon-container>
              <icon-container
                class="hover:bg-grey hover:bg-opacity-20 mr-3"
                icon="EditIcon"
                iconFill="fill-primary"
                :title="$t('edit_user')"
                @click="changeMode('edit', user)"
              ></icon-container>
              <icon-container
                class="hover:bg-grey hover:bg-opacity-20 mr-1"
                icon="TrashIcon"
                iconFill="fill-primary"
                :title="$t('delete_user')"
                @click="deleteUser(user)"
              ></icon-container></div
          ></template>
        </regular-card>
        <empty-list-feedback
          v-if="filteredUsers.length == 0"
          :color="'primary'"
          class="mt-3 mb-4"
          :text="$t('toast.no_users')"
          :key="'empty-feedback'"
        />
      </list-transition>
    </div>
    <!-- <delete-modal
            :isVisible="state.deleteModalVisible"
            @close="state.deleteModalVisible = false"
            @delete="DELETE_USER"
        ></delete-modal> -->
  </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive, watch } from "vue";
import { cloneData } from "../../../common/cloneData";
import { HELPERS_TYPES, STORE_TYPES, PRODUCTS_TYPES } from "../../../store/types";
import { useStore } from "vuex";
import { getData } from "../../../common/dataHelper";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import InputText from "../../../components/input/InputText.vue";
import InputRadio from "../../../components/input/InputRadio.vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import ListTransition from "../../../components/transitions/ListTransition.vue";
import RegularCard from "../../../components/global/RegularCard.vue";
import IconContainer from "../../../components/global/IconContainer.vue";
import EmptyListFeedback from "../../../components/global/EmptyListFeedback.vue";
import AvatarBubble from "../../../components/global/AvatarBubble.vue";
export default defineComponent({
  components: {
    BottomUpModal,
    InputText,
    InputRadio,
    InputCheckbox,
    CustomButton,
    ListTransition,
    RegularCard,
    IconContainer,
    EmptyListFeedback,
    AvatarBubble,
  },
  name: "EditUsersModal",
  emits: ["close"],
  props: {
    isVisible: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const state = reactive({
      search: "",
      user: {
        role: "Standard",
        roles: [],
      },

      deleteModalVisible: false,
      mode: "create",
    });
    /**
     *  Functions
     */
    function changeMode(mode, user) {
      if (mode == "create") {
        state.user = {
          role: "Standard",
          roles: [],
        };
      } else {
        state.user = cloneData(user);
      }
      state.mode = mode;
    }
    function deleteUser(user) {
      state.user = user;
      state.deleteModalVisible = true;
    }
    async function DELETE_USER() {
      const result = await store.dispatch(
        `${STORE_TYPES.USERS}/${PRODUCTS_TYPES.DELETE_USER}`,
        {
          user: state.user,
        }
      );
      if (getData(result, "status") == 204) {
        state.deleteModalVisible = false;
        changeMode("create");
      }
    }
    async function CREATE_USER() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "add-user-button"
      );
      state.user.url = "https://app.Gym80.de:8080/register";
      const result = await store.dispatch(
        `${STORE_TYPES.USERS}/${PRODUCTS_TYPES.CREATE_USER}`,
        {
          user: state.user,
        }
      );
      if (getData(result, "status") == 201) {
        changeMode("create");
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "add-user-button"
      );
    }
    async function UPDATE_USER() {
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
        "add-user-button"
      );
      if (
        state.user.operator_type_id == null &&
        state.user.operator_type != null
      )
        state.user.operator_type_id = state.user.operator_type.id;
      const result = await store.dispatch(
        `${STORE_TYPES.USERS}/${PRODUCTS_TYPES.UPDATE_USER}`,
        {
          user: state.user,
        }
      );

      if (getData(result, "status") == 200) {
        changeMode("create");
      }
      store.commit(
        `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
        "add-user-button"
      );
    }

    /**
     *  Computed
     */
    const filteredUsers = computed(() => {
      const filteredSearch = state.search.toLowerCase();
      let users =
        store.getters[`${STORE_TYPES.USERS}/${PRODUCTS_TYPES.GET_USERS}`];
      return users.filter((user) => {
        return user.fullname.toLowerCase().indexOf(filteredSearch) > -1;
      });
    });

    const userIsAdministrator = computed(() => {
      let roles = state.user.roles;
      if (roles != null) {
        return roles.forEach((role) => {
          if (role.name == "Administration") {
            return true;
          }
          return false;
        });
      }
      return false
    });

    /**
     * Watch
     */

    watch(
      () => props.isVisible,
      (newValue) => {
        if (!newValue) {
          changeMode("create");
        }
      }
    );

    return {
      state,
      //Functions
      changeMode,
      deleteUser,
      CREATE_USER,
      UPDATE_USER,
      DELETE_USER,
      //Computed
      filteredUsers,
      userIsAdministrator,
    };
  },
});
</script>

<style lang="scss">
// .settings-card-box {
//     max-height: calc(100vh - 520px);
//     @screen sm {
//         max-height: calc(100vh - 480px);
//     }
//     min-height: 200px;
// }
//
</style>
