<template>
    <div
        class="settingscard w-80 max-w-full mr-3 mb-3 rounded-lg border border-white transition-all bg-white p-4 cursor-pointer select-none hover:bg-opacity-60"
    >
        <h3 class="text-primary mb-2">{{ card.name }}</h3>
        <h4 class="text-grey mb-1 mr-1">{{ card.description }}</h4>
    </div>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "SettingsCard",
    components: {},
    data() {
        return {};
    },
    props: {
        card: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
});
</script>
<style lang="scss">
.settingscard:active {
    box-shadow: 2px 3px 5px rgba(silver, 0.2);
}
</style>
