<template>
    <bottom-up-modal
        :title="$t('edit_profile')"
        :isVisible="isVisible"
        @closeModal="$emit('close')"
    >
        <div
            class="flex flex-row cursor-pointer items-center"
            v-if="GET_AUTH_USER != null"
        >
            <avatar-bubble
                :name="GET_AUTH_USER.fullname"
                :textColor="`text-white text-medium`"
                class="mr-3"
            ></avatar-bubble>
            <div class="text-white-dark font-semibold">
                {{ $t("view_profile") }}
            </div>
        </div>
        <div class="flex mt-4 flex-wrap">
            <input-text
                class="w-full mb-5 sm:flex-1 sm:mr-4"
                :id="'profile_first_name'"
                :label="$t('first_name')"
                :focusWithinClass="'focus-within:border-primary'"
                :name="'first_name'"
                :labelClass="'bg-white'"
                v-model="state.user.firstname"
            ></input-text>
            <input-text
                :labelClass="'bg-white'"
                class="w-full mb-5 sm:flex-1 sm:mr-4"
                :id="'profile_last_name'"
                :label="$t('last_name')"
                :focusWithinClass="'focus-within:border-primary'"
                :name="'last_name'"
                v-model="state.user.lastname"
            ></input-text>
            <input-text
                :labelClass="'bg-white'"
                class="w-full mb-5 sm:flex-1"
                :id="'profile_email'"
                :label="$t('email')"
                :focusWithinClass="'focus-within:border-primary'"
                :name="'email'"
                v-model="state.user.email"
            ></input-text>
        </div>
        <div class="flex sm:w-1/3">
            <input-text
                :labelClass="'bg-white'"
                class="w-full mb-5 sm:mr-3"
                :id="'profile_phone'"
                :label="$t('mobile')"
                :focusWithinClass="'focus-within:border-primary'"
                :name="'phone'"
                v-model="state.user.mobile_number"
            ></input-text>
        </div>
        <custom-button
            @click="state.showPasswords = !state.showPasswords"
            class="mt-2 w-40"
            :text="!state.showPasswords ? $t('password_change') : $t('cancel')"
            :textColor="'text-primary'"
            :bgColor="'bg-transparent'"
            :id="'password-change-button'"
        >
            <template #icon>
                <icon-container
                    class="mr-2"
                    :size="'7'"
                    :icon="'lock-closed-icon'"
                    :iconFill="'fill-primary'"
                ></icon-container>
            </template>
        </custom-button>
        <height-transition>
            <div
                class="height-item transition-all flex mt-2 py-3 overflow-hidden flex-wrap"
                v-if="state.showPasswords"
                :class="state.showPasswords ? 'h-52 sm:h-14' : 'h-0'"
            >
                <input-text
                    :labelClass="'bg-white'"
                    class="w-full mb-4 sm:flex-1 sm:mr-4"
                    :id="'profile_password_old'"
                    type="password"
                    :label="$t('password_old')"
                    :name="'password_old'"
                    v-model="state.user.password_old"
                ></input-text>
                <input-text
                    :labelClass="'bg-white'"
                    class="w-full mb-4 sm:flex-1 sm:mr-4"
                    :id="'profile_password'"
                    type="password"
                    :label="$t('password')"
                    :name="'password'"
                    v-model="state.user.password"
                ></input-text>
                <input-text
                    :labelClass="'bg-white'"
                    class="w-full mb-4 sm:flex-1"
                    :id="'profile_password_confirmation'"
                    type="password"
                    :label="$t('password_repeat')"
                    :name="'password_confirmation'"
                    v-model="state.user.password_confirmation"
                ></input-text></div
        ></height-transition>
        <input-checkbox
            class="mt-5"
            :id="'profile_two_factor'"
            v-model="state.user.has_two_factor_enabled"
            @change="
                state.user.has_two_factor_enabled = !state.user
                    .has_two_factor_enabled
            "
            :defaultColor="'grey'"
            :activeColor="'primary'"
            :name="'has_two_factor_enabled'"
            :label="$t('two_fa')"
        ></input-checkbox>
        <div class="flex flex-row justify-end mt-5">
            <custom-button
                id="profile-edit-button"
                @click="UPDATE_USER"
                class="h-11 w-44"
                :text="$t('save')"
                :textColor="'text-white'"
                :bgColor="'bg-primary'"
            ></custom-button>
        </div>
    </bottom-up-modal>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { cloneData } from "../../../common/cloneData";
import AvatarBubble from "../../../components/global/AvatarBubble.vue";
import IconContainer from "../../../components/global/IconContainer.vue";
import CustomButton from "../../../components/input/CustomButton.vue";
import InputCheckbox from "../../../components/input/InputCheckbox.vue";
import InputText from "../../../components/input/InputText.vue";
import BottomUpModal from "../../../components/layout/BottomUpModal.vue";
import HeightTransition from "../../../components/transitions/HeightTransition.vue";
import { AUTH_TYPES, HELPERS_TYPES, STORE_TYPES } from "../../../store/types";

export default defineComponent({
    components: {
        AvatarBubble,
        CustomButton,
        IconContainer,
        InputText,
        HeightTransition,
        InputCheckbox,
        BottomUpModal,
    },
    name: "EditProfileModal",
    emits: ["close"],
    props: {
        isVisible: {
            type: Boolean,
            required: true,
        },
    },
    setup() {
        const store = useStore();

        /**
         * State
         */
        const state = reactive({
            user: {
                has_two_factor_enabled: false,
            },
            showPasswords: false,
        });

        /**
         * Functions
         */
        const UPDATE_USER = async () => {
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
                "profile-edit-button"
            );
            const response = await store.dispatch(
                `${STORE_TYPES.AUTH}/${AUTH_TYPES.UPDATE_USER}`,
                state.user
            );
            if (response.status == 200) {
                state.showPasswords = false;
                // store.commit(
                //     `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.ADD_TOASTMESSAGE}`,
                //     {
                //         type: "Info",
                //         message: i18n.t(
                //             "feedback.profile_successfully_updated"
                //         ),
                //     }
                // );
            }
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
                "profile-edit-button"
            );
        };
        /**
         *  Computed
         */
        const GET_AUTH_USER = computed(() => {
            return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
        });

        state.user = cloneData(GET_AUTH_USER.value)
        console.log(state.user);

        return {
            // State
            state,
            //Computed
            GET_AUTH_USER,
            // Functions
            UPDATE_USER,
        };
    },
});
</script>
