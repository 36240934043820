<template>
    <transition name="height" appear>
        <slot></slot>
    </transition>
</template>

<script>
export default {};
</script>

<style>
.height-enter-from,
.height-leave-to {
    height: 0px !important;
}
</style>
