<template>
    <div
        class="avatar-bubble relative top flex justify-center items-center rounded-full"
        :class="[
            borderColor,
            src == null ? backgroundColor : 'bg-primary',
            `w-${size} h-${size}`,
        ]"
    >
        <h3 v-if="src == null" :class="`${fontSizeClass} ${textColor}`">
            {{ acronym }}
        </h3>
        <img :src="src" :class="`w-${size} h-${size}`" alt="" v-else />
        <button
            v-if="showEdit"
            @click="emitDelete"
            class="cancel-button absolute top-4 right-4 w-0 h-0 rounded-full flex justify-center items-center bg-primary overflow-hidden transition-all opacity-0 hover:bg-primary-dark"
        >
            <cancel-icon
                class="w-2 h-2"
                :fillClass="'fill-grey-dark'"
            ></cancel-icon>
        </button>
    </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import makeAcronym from "../../common/acronym";
export default defineComponent({
    name: "AvatarBubble",
    props: {
        name: {
            type: String,
            default: "",
        },
        borderColor: {
            type: String,
            default: "border-transparent",
        },
        backgroundColor: {
            type: String,
            required: false,
            default: "bg-primary",
        },
        size: {
            type: String,
            default: "10",
        },
        src: {
            type: String,
            default: null,
        },
        showEdit: {
            type: Boolean,
            default: false,
        },
        textColor: {
            type: String,
            default: "text-white-dark font-medium",
        },
    },
    emits: ["delete"],
    setup(props, ctx) {
        /**
         * Emits
         */
        const emitDelete = () => {
            ctx.emit("delete");
        };

        /**
         * Computed
         */
        const acronym = computed(() => {
            return makeAcronym(props.name);
        });
        const fontSizeClass = computed(() => {
            return parseInt(props.size) <= 6 ? "text-xs" : "text-md";
        });

        return {
            // Emits
            emitDelete,
            // Computed
            acronym,
            fontSizeClass,
        };
    },
});
</script>

<style lang="scss">
.avatar-bubble {
    &:hover {
        .cancel-button {
            @apply opacity-100;
            @apply w-5;
            @apply h-5;
            @apply -top-1;
            @apply -right-1;
        }
    }
}
</style>
