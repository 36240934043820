<template>
    <transition name="fade" appear>
        <div :title="message" class="absolute right-2" style="z-index: 100">
            <error-icon :fillClass="'fill-primary'" :bgClass="'fill-white'" />
        </div>
    </transition>
</template>

<script>
import { defineComponent } from "vue";
export default defineComponent({
    name: "ErrorMessage",
    components: {},
    props: {
        message: {
            type: String,
            required: true,
        },
    },
});
</script>
