<template>
    <main>
        <title-row :title="$t('settings')">
            <template #items>
                <input-text
                    :id="'filter-text'"
                    :label="$t('filter')"
                    v-model="state.filters.search"
                    :labelClass="'bg-white-dark'"
                    class="w-64"
                />
            </template>
        </title-row>
        <div class="flex pb-2 border-b border-grey-light mb-5">
            <avatar-bubble
                class="mr-4"
                :textColor="`text-white text-medium`"
                :name="GET_AUTH_USER.fullname"
            ></avatar-bubble>
            <div class="flex flex-col items-start w-60">
                <div class="text-white-dark">
                    {{ GET_AUTH_USER.fullname }}
                </div>
                <div
                    class="text-primary font-semibold cursor-pointer"
                    @click="state.selectedModalName = 'editProfileModal'"
                >
                    {{ $t("view_profile") }}
                </div>
            </div>
        </div>
        <div>
            <settings-box
                v-for="(settingsGroup, index) in filteredSettings"
                :key="index"
                :settingsGroup="settingsGroup"
                @openmodal="
                    (modalName) => {
                        state.selectedModalName = modalName;
                    }
                "
            />
        </div>
        <shortcuts-modal
            @close="state.selectedModalName = ''"
            :isVisible="state.selectedModalName == 'shortcutsModal'"
        />
        <edit-profile-modal
            @close="state.selectedModalName = ''"
            :isVisible="state.selectedModalName == 'editProfileModal'"
        />
        <edit-users-modal
            @close="state.selectedModalName = ''"
            :isVisible="state.selectedModalName == 'editUsersModal'"
        />
    </main>
</template>

<script>
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import InputText from "../../components/input/InputText.vue";
import TitleRow from "../../components/layout/TitleRow.vue";
import { AUTH_TYPES, HELPERS_TYPES, SETTINGS_TYPE, STORE_TYPES, PRODUCTS_TYPES } from "../../store/types";
import SettingsBox from "./SettingsBox.vue";
import ShortcutsModal from "./modals/ShortcutsModal.vue";
import AvatarBubble from "../../components/global/AvatarBubble.vue";
import EditProfileModal from "./modals/EditProfileModal.vue";
import EditUsersModal from "./modals/EditUsersModal.vue";

export default defineComponent({
    components: {
        TitleRow,
        InputText,
        SettingsBox,
        ShortcutsModal,
        AvatarBubble,
        EditProfileModal,
        EditUsersModal,
    },
    setup() {
        const i18n = useI18n();
        const store = useStore();
        /**
         *  State
         */
        const state = reactive({
            filters: {
                search: "",
            },
            selectedModalName: "",

            settings: [
                {
                    header: i18n.t("shortcuts"),
                    elements: [
                        {
                            name: i18n.t("speedUpWork"),
                            description: i18n.t("useShortcutsText"),
                            modalId: "shortcutsModal",
                        },
                    ],
                },
                {
                    header: i18n.t("administration"),
                    elements: [
                        {
                            name: i18n.t("edit_users"),
                            description: i18n.t("edit_users_text"),
                            modalId: "editUsersModal",
                        },
                    ],
                },
            ],
        });

        /**
         *  Functions
         */
        function SET_FILTER_ACTIVE_ROUTE() {
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_FILTER_ACTIVE_ROUTE}`
            );
        }
        async function FETCH_SETTINGS() {
            return store.dispatch(
                `${STORE_TYPES.SETTINGS}/${SETTINGS_TYPE.FETCH_SETTINGS}`
            );
        }

        async function FETCH_USERS() {
            var users = await store.dispatch(
                `${STORE_TYPES.USERS}/${PRODUCTS_TYPES.FETCH_USERS}`
            );
            console.log(users);
        }
        /**
         *  Computed
         */
        const filteredSettings = computed(() => {
            const search = state.filters.search.toLowerCase();
            return state.settings.map((setting) => {
                return {
                    header: setting.header,
                    elements: setting.elements.filter(function(element) {
                        if (
                            element.name.toLowerCase().indexOf(search) > -1 ||
                            element.description.toLowerCase().indexOf(search) >
                                -1
                        ) {
                            return true;
                        } else {
                            return false;
                        }
                    }),
                };
            });
        });
        const GET_AUTH_USER = computed(() => {
            return store.getters[`${STORE_TYPES.AUTH}/${AUTH_TYPES.GET_USER}`];
        });

        /**
         *  Created
         */

        store.commit(
            `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.SET_LOADING_ID}`,
            "isLoadingData"
        );
        
        Promise.all([FETCH_SETTINGS(),
            FETCH_USERS()]).then(() => {
            store.commit(
                `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.REMOVE_LOADING_ID}`,
                "isLoadingData"
            );
        });

        return {
            state,
            SET_FILTER_ACTIVE_ROUTE,
            filteredSettings,
            GET_AUTH_USER,
        };
    },
});
</script>
<style lang="scss">
.justify-start-important {
    justify-content: flex-start !important;
}
</style>
