<template>
    <div :class="size + ' rounded-full relative'">
        <div
            class="btn cursor-pointer flex flex-col justify-center items-center rounded-full bg-transparent"
            :class="'btn ' + btnClasses"
            :title="title"
        >
            <slot v-if="icon == null"></slot>
            <component
                v-else
                :is="icon"
                :fillClass="iconFill"
                :strokeClass="iconStroke"
            ></component>
        </div>
    </div>
</template>
<script>
import { defineComponent, computed } from "vue";
import { useStore } from "vuex";
import { HELPERS_TYPES, STORE_TYPES } from "../../store/types";
export default defineComponent({
    name: "IconContainer",
    emits: ["clickedContainer"],
    props: {
        id: {
            type: String,
            required: false,
        },
        title: {
            type: String,
            default: null,
        },
        btnClasses: {
            type: String,
            default: "",
            required: false,
        },
        size: {
            type: String,
            default: "icon--medium",
            required: false,
        },
        icon: {
            type: String,
            default: null,
        },
        iconFill: {
            type: String,
            default: "fill-black",
        },
        iconStroke: {
            type: String,
            default: "stroke-black",
        },
    },
    setup(props) {
        const store = useStore();
        /**
         *  Computed
         */
        const GET_LOADING_IDS = computed(
            () =>
                store.getters[
                    `${STORE_TYPES.HELPERS}/${HELPERS_TYPES.GET_LOADING_IDS}`
                ]
        );
        const isLoading = computed(() => {
            for (const key in GET_LOADING_IDS) {
                if (
                    Object.prototype.hasOwnProperty.call(GET_LOADING_IDS, key)
                ) {
                    const element = GET_LOADING_IDS[key];
                    if (element == props.id) {
                        return true;
                    }
                }
            }
            return false;
        });
        return {
            isLoading,
            GET_LOADING_IDS,
        };
    },
});
</script>
<style lang="scss">
.hover--fill-white:hover {
    svg path,
    svg rect {
        fill: white;
    }
}
.hover--fill-error:hover {
    svg path,
    svg rect {
        @apply fill-red;
    }
}
.hover--fill-primary:hover {
    svg path,
    svg rect {
        @apply fill-primary;
    }
}
.icon--medium {
    width: 45px;
    height: 45px;
    .btn {
        height: 45px;
        width: 45px;
    }
}
.icon--small {
    width: 30px;
    height: 30px;
    .btn {
        height: 30px;
        width: 30px;
    }
}
.loading__border--item {
    box-sizing: border-box;
    animation: spinning 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}
.loading__border--item:nth-child(1) {
    animation-delay: -0.45s;
}
@keyframes spinning {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
</style>
