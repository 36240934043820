<template>
    <transition-group name="list-transition">
        <slot></slot>
    </transition-group>
</template>

<script>
export default {};
</script>

<style>
.list-transition-item {
    @apply transition-all;
    @apply duration-short;
}
.list-transition-enter-active,
.list-transition-leave-to {
    opacity: 0;
}
</style>
