<template>
    <div
        class="inputcheck max-w-full flex flex-row items-center cursor-pointer"
        @click="changeSelect"
    >
        <div
            class="w-6 h-6 border rounded-md flex items-center justify-center mr-2 border-opacity-80 transition-colors"
            :class="['border-' + defaultColor]"
        >
            <checked-icon
                :fillClass="
                    modelValue ? 'fill-' + activeColor : 'fill-transparent'
                "
            />
        </div>
        <label
            class="cursor-pointer text-opacity-80 transition-colors"
            :class="'text-' + defaultColor"
            :for="id"
            >{{ label }}</label
        >
    </div>
</template>

<script>
import { defineComponent } from "vue";
import CheckedIcon from "../icons/CheckedIcon.vue";
export default defineComponent({
    components: { CheckedIcon },
    name: "InputCheckbox",
    props: {
        id: {
            type: String,
            required: true,
        },
        name: {
            type: String,
            required: false,
        },
        modelValue: {
            type: Boolean,
            required: true,
        },
        label: {
            type: String,
            required: false,
        },

        defaultColor: {
            type: String,
            required: true,
        },
        activeColor: {
            type: String,
            required: true,
        },
    },
    emits: ["change"],
    setup(props, ctx) {
        /**
         * emits
         */
        function changeSelect() {
            ctx.emit("change", !props.modelValue);
        }

        return {
            //emits
            changeSelect,
        };
    },
});
</script>
<style lang="scss">
.inputcheck:hover {
    .checkbox,
    label {
        @apply border-opacity-100;
        @apply text-opacity-100;
    }
}
</style>
