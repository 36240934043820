<template>
    <h3 class="font-semibold mb-3" v-show="settingsGroup.elements.length > 0">
        {{ settingsGroup.header }}
    </h3>
    <div class="flex flex-row justify-start items-stretch flex-wrap">
        <settings-card
            v-for="card in settingsGroup.elements"
            :key="card"
            :card="card"
            @click="$emit('openmodal', card.modalId)"
        />
    </div>
</template>

<script>
import { defineComponent } from "vue";
import SettingsCard from "./SettingsCard.vue";
export default defineComponent({
    name: "SettingsBox",
    components: { SettingsCard },

    emits: ["openmodal"],
    props: {
        settingsGroup: {
            type: Object,
            default: () => {
                return {};
            },
        },
    },
});
</script>
